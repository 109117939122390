import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/index.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    component: login,
    title: '登录',
    meta: {
      title: '登录'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: '亿网充共享充电桩管理系统'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue')
  },
  {
    path: '/airplay',
    name: 'airplay',
    meta: {
      title: '亿网充共享充电桩管理系统'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/airplay/index.vue')
  }
]



const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  const role = localStorage.getItem('ms_username');
  next();
 /* if (!role && to.path !== '/login') {
    next('/login');
  } else {
    next();
  }*/
});

export default router
