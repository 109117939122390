let state = {
    // 当前用户信息
    commonUserInfo:{

    },
    // 权限树
    commonUserInfoTree:[

    ],
    // 将当前top导航选中tab
    currentTopTab: null,
    // 进度
    percentage: 0,
    // 显示进度组件
    showPercentage: false,
    // 下载的密码
    downLoadKeyList: [],
    // 订单id
    rechargeOrderId: '',
    // 占桩id
    accountPileFeeOrderId: '',
};
export default state;
