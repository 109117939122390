export default {
    name: "top-nav",
    props:{
       // 导航数据
        menusArr:{
            type: Array,
            default: ()=>{
                return [
                    {
                        title:'站所站所管理',
                        children:[
                            {
                                title:'站所管理'
                            },
                            {
                                title:'车位管理'
                            }

                        ]
                    },
                    {
                        title:'设备信息查看',
                        children:[
                            {
                                title:'设备信息'
                            }
                        ]
                    }
                ]
            }
        }
    },
    data() {
        return {
            // 打开状态
            opened: true,
            // 默认选中的节点
            defaultActive: '0-0'
        }
    },
    methods:{
        // 是否展示图标tab模式
        openView() {
            this.opened = !this.opened;
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        // 子节点的选中事件，用途是切换中间区域content
        changeLeftTabHandler(item, itemc) {
            this.$emit('changeLeftTabHandler', item, itemc)
        },
        // 设置节点选中的回调
        selectmenu (index) {
            this.defaultActive = index
            // window.localStorage.setItem('activeMenu', this.activeMenu)
        },
    }
}
