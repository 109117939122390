
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/index.js';
// 公用组件
import {} from '@/components/common/index.js';
// 全局方法
import {} from '@/js/app/global.js';
import {} from '@/assets/font-img/iconfont.css'
import ElementUI from 'element-ui';

import horizontalScroll from 'el-table-horizontal-scroll'
Vue.use(horizontalScroll)

// 修改el-dialog 默认点击遮罩层的时候弹框不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false;
import 'element-ui/lib/theme-chalk/index.css';
// 配置模块
import {} from '@/js/config.js';
import axios from 'axios'
Vue.prototype.axios = axios;
import echarts from "@/js/common/echarts.js";


Vue.prototype.$echarts = echarts;
// axios拦截器增加了 element-ui的loading效果
import {} from '@/js/common/axios-catch.js'
Vue.use(ElementUI);

// 引入echarts


Vue.config.productionTip = false;

// 如果本地有token，将axios的默认headers加上token值
router.beforeEach((to, from, next) => {
  console.log('to=', to)
  if (localStorage.getItem('token')) {
    if(to.name !== 'login'){
      axios.defaults.headers['token'] = localStorage.getItem('token');
    }
    next();
  } else {
    if (to.path === '/login') {
      next();
    } else {
      next('/login');
    }
  }
});



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
