
import axios from 'axios';
import qs from 'qs';

const module = {
    name: 'login',
    data() {
        return {
            user: {
                username: "",
                password: ""
            },
            rules: {
                // usn和prop对应
                username: [
                    // blur表示鼠标失焦时触发
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur'}
                ]
            },
            // 防抖函数
            debounceTimeout: null,
            // 忘记密码弹框
            dialogVisible:false,
        }
    },
    methods: {
        // 忘记密码提示
        forgetPwd(){
           this.dialogVisible = true
        },
        loginHandler() {
            // 防抖处理
            this.debounce(() => {
                this.$refs['loginForm'].validate(valid => {
                    if (valid) {
                        // 请求
                        this.loginRequeast().then((data) => {
                            // 用户信息存入store中
                            this.$store.commit('commonUserInfo', data.data);
                            localStorage.setItem("ms_username", this.user.username);
                            axios.defaults.headers['token'] = data.data.data.token;
                            localStorage.setItem("token", data.data.data.token);
                            this.$router.push({
                                path: '/index'
                            });
                            // 体验不好
                            // this.$message({
                            //     message: '登录成功!',
                            //     type: 'success',
                            //     duration: 2000,
                            //     showClose : true,
                            // });
                        }).catch((err) => {
                            this.$message({
                                type: 'error',
                                message: `${err.msg}`,
                                duration: 2000,
                                showClose: true
                            });
                        });
                    }
                });
            }, 200);
        },
        /**
         * 防抖
         * @param fn
         * @param wait
         * @returns {function(): void}
         */
        debounce(fn, wait) {
            if (this.debounceTimeout !== null) {
                clearTimeout(this.debounceTimeout);
                this.debounceTimeout = null;
            }
            this.debounceTimeout = setTimeout(fn, wait);
        },
        // 登录请求
        loginRequeast() {
            const _data = {
                username: this.user.username,
                password: this.user.password
            }
            const _method = window.fdConfig.methodPost;
            const _url = window.fdConfig.isDebug ? window.fdConfig.url.login.loginPort : window.fdConfig.url.login('loginPort');
            return axios({
                method: _method,
                url: _url,
                data: qs.stringify(_data),
                headers: {'content-type': 'application/x-www-form-urlencoded'}
            })
        },
        enterKey(event) {
            const componentName = this.$options.name;
            if (componentName == "login") {
                const code = event.keyCode
                    ? event.keyCode
                    : event.which
                        ? event.which
                        : event.charCode;
                if (code == 13) {
                    this.loginHandler();
                }
            }
        },
        enterKeyupDestroyed() {
            document.removeEventListener("keyup", this.enterKey);
        },
        enterKeyup() {
            document.addEventListener("keyup", this.enterKey);
        }
    },
    mounted() {
        // 绑定enter事件
        this.enterKeyup();
    },
    destroyed() {
        // 销毁enter事件
        this.enterKeyupDestroyed();
    }
}
export default module;
