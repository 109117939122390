import fdEventBus from '@/js/common/fdEventBus.js'
export default {
    name: "crumbs-slide",
    props: {
        // 记录面包屑导航的数据
        dataList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    watch:{
      dataList: {
          deep: true,  // 深度监听
          handler(newValue, oldValue) {
              this.activeNumber = newValue.length-1
          }
      }
    },
    data() {
        return {
            // 滑动的x轴距离 px为单位
            slideStyle: 0,
            // 方向判断
            direction: {
                left: 1,
                right: -1
            },
            // 单位滑动距离
            unitDistance: 155,
            // TODO 方案一，被废弃了！！！原因是体验感不好，连续点击会有顿挫
            // 视图需要减去的宽度，自定义的padding等
            //cutPadding: 70,
            // flag: false,
            // debounceTimeout: ''
            currentNumber: 0,
            // 被选中的item index
            activeNumber: 0
        }
    },
    computed: {
        // 最后的节点
        lastNode(){
            // 外层宽度，li不可以突破这个宽度
            const crumbsSlideBoxWidth = this.$refs.crumbsSlideBox.offsetWidth;
            // 可以点击的次数
            const remain = (this.dataList.length * this.unitDistance - crumbsSlideBoxWidth) / this.unitDistance;
            return this.currentNumber === remain;
        }
    },
    methods: {
        // 滑动事件 flag为左右依据
        slideHandler(flag, moveSize = 1) {
            // 外层宽度，li不可以突破这个宽度
            const crumbsSlideBoxWidth = this.$refs.crumbsSlideBox.offsetWidth;
            // 可以点击的次数
            const remain = (this.dataList.length * this.unitDistance - crumbsSlideBoxWidth) / this.unitDistance;
            // 进行dom偏移加减处理
            this.slideStyle += (this.unitDistance * (this.direction[flag] * moveSize));
            // 具体左右进行次数增减
            if (moveSize === 1) {
                this.direction[flag] === -1 ? this.currentNumber++ : this.currentNumber--;
            }else {
                this.currentNumber = remain;
            }
            // 当滑动距离为正数说明到头了不可以进行滑动
            if (this.slideStyle > 0) {
                this.slideStyle = 0;
                this.currentNumber = 0;
            }
            // 当超过了次数说明右侧到头了不可以点击
            if (this.currentNumber > remain) {
                this.currentNumber--;
                // 回滚距离
                this.slideStyle -= (this.unitDistance * this.direction[flag]);
            }
            // TODO 方案一，被废弃了！！！
            // if (this.flag && flag === 'right'){
            //     return;
            // }
            // this.flag = false;
            // this.debounce(()=>{
            //     this.slideStyle += (this.unitDistance * this.direction[flag]);
            //     // 如果大于0说明左侧到头了重新置为0
            //     if (this.slideStyle > 0){
            //         this.slideStyle = 0;
            //     }
            // }, 300)
        },
        // 删除当前item方法
        deleteItem(index){
            this.$emit('deleteItem', index);
            if(this.dataList.length >= 10){
                this.slideHandler('left', 1);
            }
        },
        // 跳转对应的页面
        jumpRout(item, index){
            this.activeNumber = index;
            this.$emit('jumpRout', item);
            if(this.dataList.length > 10){
                // 外层宽度，li不可以突破这个宽度
                const crumbsSlideBoxWidth = this.$refs.crumbsSlideBox.offsetWidth;
                // 可以点击的次数
                const remain = (this.dataList.length * this.unitDistance - crumbsSlideBoxWidth) / this.unitDistance;
               // 可点次数 减去 当前已点次数 === 0 的时候，说明当前菜单在最左侧，num传默认值1即可，其他情况传递差值
                let num = remain - this.currentNumber === 0 ? 1 : remain - this.currentNumber;
                this.slideHandler('right', num);
            }
        },
        // TODO 方案一，被废弃了！！！
        // debounce(fn, wait) {
        //     if (this.debounceTimeout !== null) {
        //         clearTimeout(this.debounceTimeout);
        //         this.debounceTimeout = null;
        //     }
        //     this.debounceTimeout = setTimeout(fn, wait);
        // },
    },
    created() {

        fdEventBus.$on('jumpRoutTrigger', (item, index)=>{
            this.jumpRout(item, index)
        });

        // 监听页面缩放
        window.addEventListener('resize', () => {
            // 重置滑轨
            this.slideStyle = 0;
            this.currentNumber = 0;
        }, false);
    },
    mounted() {
        // TODO 方案一，被废弃了！！！
        // let _this = this;
        // _this.$refs.crumbsSlideUl.addEventListener("transitionend",function(e){
        //     console.log(_this.$refs.crumbsSlideLi[_this.$refs.crumbsSlideLi.length-1].getBoundingClientRect().right)
        //     console.log("animationend");
        //     // 获取当前视图的真实x宽度
        //     const ClientRectX = _this.$refs.crumbsSlideLi[_this.$refs.crumbsSlideLi.length-1].getBoundingClientRect().right;
        //     // 如果最后一个li的位置小于真实视口的宽度不可以继续向左移动
        //     if (ClientRectX < window.innerWidth - _this.cutPadding){
        //         _this.flag = true;
        //         // 因为上面+=操作已经做了，需要减回去
        //     }
        // },false);
    },
    beforeDestroy() {
        fdEventBus.$off('jumpRoutTrigger')
        window.removeEventListener('resize', () => {
            // 重置滑轨
            this.slideStyle = 0;
            this.currentNumber = 0;
        }, false);
        // TODO 方案一，被废弃了！！！
        // this.$refs.crumbsSlideUl.removeEventListener("transitionend",function(e){
        //     console.log("animationend");
        // },false);
    }
}
